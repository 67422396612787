import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngxs/store';
import { ImportsStateQueries } from './state/imports.queries';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { STRINGS } from '../../model/imports.strings';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import {
  AddFilterParam,
  AddSearchParam,
  ColumnsSelected,
  HideColumn,
  Paginate,
  ReloadDatatable,
  RemoveAllFilters,
  RemoveSearchParam,
  ResetImports,
  Sort,
  ToggleFilter,
} from './actions/imports.actions';
import { DataTableV2Component } from 'src/app/shared/components/design-system/data-table-v2/data-table-v2.component';

import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { Import } from 'src/app/shared/models/tableImports/imports.model';
import { NavigateToWithQueryParams } from 'src/app/core/actions/navigation.action';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { ImportsState } from './state/imports.state';
import {
  ColumnFilterParam,
  ColumnHide,
  Filtering,
  Pagination,
  Search,
  Sortable,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { STRINGS as DETAILS_STRINGS } from '../import-details/components/import-details-data-table/model/import-details-data-table.strings';
import { RetryImport } from '../import-details/components/import-details-data-table/actions/import-details-data-table.actions';
import { STRINGS as TOOLTIPS } from 'src/app/features/products-v2/model/products-v2.strings';
import { COLUMNS_ORDER } from './model/data-table.model';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';

@Component({
  selector: 'app-imports-data-table',
  templateUrl: './imports-data-table.component.html',
  styleUrls: ['./imports-data-table.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportsDataTableComponent
  extends DataTableV2Component<Import[]>
  implements Pagination, Search, Filtering, ColumnHide, Sortable, OnDestroy
{
  totalElements = select(ImportsStateQueries.totalElements);
  pageSizeOptions = select(ImportsStateQueries.pageSizeOptions);
  pageSize = select(ImportsStateQueries.pageSize);
  currentPage = select(ImportsStateQueries.currentPage);
  selectableColumns = select(ImportsStateQueries.selectableColumns);
  displayedColumns = select(ImportsStateQueries.displayedColumns);
  lastDisplayedColumn = select(ImportsStateQueries.lastDisplayedColumn);
  datasource = select(ImportsStateQueries.imports);
  loading = select(ImportsStateQueries.loading);
  filterOpened = select(ImportsStateQueries.filterOpened);
  activeFilters = select(ImportsStateQueries.activeFilters);
  filters = select(ImportsStateQueries.filters);
  searchBy = select(ImportsStateQueries.search);

  canRefresh = select(ImportsState.canRefresh());

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly TABLE_COLUMNS = STRINGS.columns.imports;

  readonly TABLE_STATES = STRINGS.metadata.states;

  readonly TABLE_ACTIONS = STRINGS.metadata.actions;

  readonly DETAILS_STRINGS = DETAILS_STRINGS.metadata;

  readonly TOOLTIPS = TOOLTIPS.tooltips.sales;

  readonly COMMON_STRINGS = MESSAGES.common;

  readonly RELATED_STATE = ImportsState;

  readonly COLUMNS_ORDER = COLUMNS_ORDER;

  constructor(private store: Store) {
    super();
  }

  refreshDatatable(): void {
    this.store.dispatch(new ReloadDatatable());
  }

  onPageChange(pagination: PageEventV2): void {
    this.store.dispatch(new Paginate(pagination));
  }

  onColumnsSelected(columnSelection: ColumnSelectionV3): void {
    this.store.dispatch(new ColumnsSelected(columnSelection));
  }

  onSort(sort: Sorted): void {
    this.store.dispatch(new Sort(sort));
  }

  onColumnHidden(columnKey: string): void {
    this.store.dispatch(new HideColumn(columnKey));
  }

  addSearchParam(param: string) {
    this.store.dispatch(new AddSearchParam(param));
  }

  removeSearchParam(param: string) {
    this.store.dispatch(new RemoveSearchParam(param));
  }

  toggleFilter(): void {
    this.store.dispatch(new ToggleFilter());
  }

  clearAllFilters(): void {
    this.store.dispatch(new RemoveAllFilters());
  }

  onFiltered(params: ColumnFilterParam): void {
    this.store.dispatch(new AddFilterParam(params));
  }

  onImportSelected(data: Import): void {
    this.store.dispatch(
      new NavigateToWithQueryParams(['imports', 'details'], {
        importUUID: data.uuid,
        entityName: data.entityName,
      })
    );
  }

  retry(importUuid: string) {
    this.store.dispatch(new RetryImport(importUuid));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetImports());
  }
}
