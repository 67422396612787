export const STRINGS = {
  columns: {
    errors: {
      IDENTIFIER: {
        key: 'IDENTIFIER',
        name: $localize`Identifier name`,
        filterKey: 'IDENTIFIER',
        currency: false,
        containers: false,
      },
      ERRORS: {
        key: 'ERRORS',
        name: $localize`Error description`,
        filterKey: 'ERRORS',
        currency: false,
        containers: false,
      },
    },
  },
  tooltips: {
    required: $localize`This field is required`,
  },
  notifications: {},
  metadata: {
    title: $localize`Import errors`,
    tableTitle: $localize`Imports`,
    tableSubtitle: $localize`All the errors faced during importing this file are listed below.`,
    retryImport: $localize`Retry import`,
    states: {
      loading: $localize`Loading contents...`,
      noData: $localize`There is no data available.`,
    },
  },
};
