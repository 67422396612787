import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { InitializeImportDetails } from './components/import-details-data-table/actions/import-details-data-table.actions';
import { STRINGS } from '../../model/imports.strings';
import { ActivatedRoute } from '@angular/router';
import { ImportsStateQueries } from '../imports-data-table/state/imports.queries';
import { Import } from 'src/app/shared/models/tableImports/imports.model';
import { STRINGS as DETAILS_STRINGS } from './components/import-details-data-table/model/import-details-data-table.strings';

@Component({
  selector: 'app-import-details',
  templateUrl: './import-details.component.html',
  styleUrl: './import-details.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportDetailsComponent implements OnInit, OnDestroy {
  readonly COMMON_STRINGS = MESSAGES.common;

  readonly STRINGS = STRINGS.metadata;

  readonly DETAILS_STRINGS = DETAILS_STRINGS.metadata;

  @Select(ImportsStateQueries.currentImport)
  currentImport$: Observable<Import>;

  @Select(ImportsStateQueries.currentImportName)
  currentImportName$: Observable<string>;

  private _destroy$: Subject<void> = new Subject<void>();

  private _importUuid: string | null = null;

  private _entityName: string | null = null;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._importUuid =
      this.activatedRoute.snapshot.queryParamMap.get('importUUID');

    this._entityName =
      this.activatedRoute.snapshot.queryParamMap.get('entityName');

    this.store.dispatch(
      new InitializeImportDetails(this._importUuid, this._entityName)
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
