import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ImportsPage } from './imports.page';
import { ImportDetailsComponent } from './components/import-details/import-details.component';

export const routes: Routes = [
  {
    path: '',
    component: ImportsPage,
  },
  {
    path: 'details',
    component: ImportDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ImportsRoutingModule {}
