import { Injectable } from '@angular/core';
import {
  Action,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';

import {
  columnsGroupsMap,
  defaultColumnsV2,
  defaultFiltersV2,
  defaultPagination,
  defaultSort,
  filtersGroupsMap,
} from '../model/data-table.model';
import {
  LoadFailed,
  LoadSucceed,
  SaveFailed,
} from 'src/app/core/actions/app.action';
import { Observable, catchError, concatMap, mergeMap, tap } from 'rxjs';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { AccountSettingsUpdate } from 'src/app/shared/models/account/account-settings.model';
import { AccountState } from 'src/app/core/states/account.state';
import { AccountSettingsService } from 'src/app/core/api/account/v2/account-settings.service';
import { LoadAccountSettings } from 'src/app/core/actions/settings.action';

import {
  DatatableState,
  DatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/state/data-table.state';
import {
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatableParam,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  Sorted,
  SortedOrder,
} from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { FilterTypesOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';
import {
  AddFilterParam,
  AddSearchParam,
  ColumnsSelected,
  HideColumn,
  InitializeImportDetails,
  LoadImportDetails,
  Paginate,
  RemoveAllFilters,
  ResetImportDetails,
  ResetPagination,
  ResetPaginationAndLoadData,
  Sort,
  ToggleFilter,
} from '../actions/import-details-data-table.actions';
import { Errors, ImportDetails } from '../../../model/import-details.model';
import { ImportsService } from 'src/app/core/api/imports/imports.service';
import { ActivatedRoute } from '@angular/router';
import { LoadImport } from '../../../../imports-data-table/actions/imports.actions';
import { ImportsStateQueries } from '../../../../imports-data-table/state/imports.queries';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { WebshopSelected } from 'src/app/core/actions/webshop.action';
import { patch } from '@ngxs/store/operators';
import { Filter } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';

export interface ImportsDataTableStateModel extends DatatableStateModel {
  loading: boolean;
  errorLines: Errors[];
  entityName: string;
  page: Pageable;
}

@State<ImportsDataTableStateModel>({
  name: 'importsDataTableState',
  defaults: {
    pagination: defaultPagination,
    columnsGroups: defaultColumnsV2,
    filtersGroups: defaultFiltersV2,
    sortBy: defaultSort,
    search: [],
    filterOpened: false,
    loading: true,
    errorLines: null,
    entityName: null,
    page: { totalElements: 0 },
  },
})
@Injectable()
export class ImportsDataTableState extends DatatableState {
  static filterDataByColumnKey(columnKey: string) {
    return createSelector(
      [ImportsDataTableState],
      (state: ImportsDataTableStateModel) => {
        const groupKey = filtersGroupsMap.get(columnKey);

        if (!groupKey) {
          throw new Error(`Column group ${groupKey} does not exist`);
        }

        if (state.filtersGroups[groupKey].columns[columnKey] === undefined) {
          throw new Error(`Column ${columnKey} does not exist`);
        }

        const sortBy = new Map(Object.entries(state.sortBy));

        return {
          columnKey,
          filter: state.filtersGroups[groupKey].columns[columnKey],
          filtered:
            !!state.filtersGroups[groupKey].columns[columnKey].params.value,
          sorted: sortBy.has(
            state.filtersGroups[groupKey].columns[columnKey]?.key
          )
            ? (sortBy.get(state.filtersGroups[groupKey].columns[columnKey].key)
                .order as SortedOrder)
            : null,
        };
      }
    );
  }

  constructor(
    private store: Store,
    private importsService: ImportsService,
    private accountSettingsService: AccountSettingsService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  @Action(InitializeImportDetails, { cancelUncompleted: true })
  initialize(
    ctx: StateContext<ImportsDataTableStateModel>,
    payload: InitializeImportDetails
  ) {
    ctx.patchState({
      entityName: payload.entityName,
    });

    return ctx
      .dispatch(new LoadImport(payload.importUuid))
      .pipe(concatMap(() => ctx.dispatch(new LoadImportDetails())));
  }

  @Action(LoadImportDetails, { cancelUncompleted: true })
  loadImportDetails(ctx: StateContext<ImportsDataTableStateModel>) {
    ctx.patchState({
      loading: true,
    });

    return this._fetchImporDetails(ctx).pipe(
      tap((imports: ImportDetails) => {
        ctx.patchState({
          loading: false,
          errorLines: imports.data,
          page: {
            totalElements: imports.metadata.page.totalElements,
          },
        });
      }),
      concatMap(() => ctx.dispatch(new LoadSucceed(false)))
    );
  }

  @Action(Paginate, { cancelUncompleted: true })
  paginate(ctx: StateContext<ImportsDataTableStateModel>, payload: Paginate) {
    const state = ctx.getState();

    const didPageSizeChange =
      ctx.getState().pagination.size !== payload.pagination.pageSize;

    if (didPageSizeChange) {
      return this._saveTableSizePersistance(
        ctx,
        payload.pagination.pageSize
      ).pipe(
        tap(() => {
          ctx.patchState({
            pagination: {
              ...state.pagination,
              page: payload.pagination.pageIndex,
              size: payload.pagination.pageSize,
            },
          });
        }),
        mergeMap(() => ctx.dispatch(new LoadImportDetails()))
      );
    }

    ctx.patchState({
      pagination: {
        ...state.pagination,
        page: payload.pagination.pageIndex,
        size: payload.pagination.pageSize,
      },
    });

    return ctx.dispatch(new LoadImportDetails());
  }

  @Action(AddFilterParam, { cancelUncompleted: true })
  addFilterParam(
    ctx: StateContext<ImportsDataTableStateModel>,
    payload: AddFilterParam
  ) {
    const groupKey = filtersGroupsMap.get(payload.param.columnKey);

    if (!groupKey) return;

    this.addGroupParam(ctx, groupKey, payload.param.columnKey, {
      operator: payload.param.optionSelected as FilterTypesOptionsV2,
      subOperator: payload.param.subOperator,
      value: payload.param.values,
    });

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(AddSearchParam, { cancelUncompleted: true })
  addSearchParam(
    ctx: StateContext<ImportsDataTableStateModel>,
    payload: AddSearchParam
  ) {
    ctx.patchState({
      search: payload.param,
    });

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(Sort, { cancelUncompleted: true })
  sortTable(ctx: StateContext<ImportsDataTableStateModel>, payload: Sort) {
    return this.sort(ctx, payload.sort);
  }

  @Action(ColumnsSelected, { cancelUncompleted: true })
  columnsSelected(
    ctx: StateContext<ImportsDataTableStateModel>,
    payload: ColumnsSelected
  ) {
    return this._saveTableColumnsPersistance(
      ctx,
      payload.selection.columnsGroups
    ).pipe(
      tap(() => {
        ctx.setState(
          patch({
            columnsGroups: {
              ...ctx.getState().columnsGroups,
              ...payload.selection.columnsGroups,
            },
          })
        );
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(HideColumn, { cancelUncompleted: true })
  hideColumn(
    ctx: StateContext<ImportsDataTableStateModel>,
    payload: HideColumn
  ) {
    const groupKey = columnsGroupsMap.get(payload.columnKey);

    if (!groupKey) return;

    const newColumnSelection = this.hideGroupColumnFromColumnKey(
      ctx.getState().columnsGroups,
      groupKey,
      payload.columnKey
    );

    return this._saveTableColumnsPersistance(ctx, newColumnSelection).pipe(
      tap(() => {
        ctx.patchState({
          columnsGroups: newColumnSelection,
        });
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(RemoveAllFilters, { cancelUncompleted: true })
  removeAllFilters(ctx: StateContext<ImportsDataTableStateModel>) {
    this._removeAllFilters(ctx);

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(WebshopSelected)
  removeAllFiltersOnWebshopChange(
    ctx: StateContext<ImportsDataTableStateModel>
  ) {
    this._removeAllFilters(ctx);
  }

  @Action(ToggleFilter, { cancelUncompleted: true })
  toggleFilter(ctx: StateContext<ImportsDataTableStateModel>) {
    ctx.patchState({
      filterOpened: !ctx.getState().filterOpened,
    });
  }

  @Action(ResetPagination)
  resetPagination(
    ctx: StateContext<ImportsDataTableStateModel>,
    payload: ResetPagination
  ) {
    ctx.patchState({
      pagination: {
        ...ctx.getState().pagination,
        page: payload.page,
      },
    });
  }

  @Action(ResetImportDetails, { cancelUncompleted: true })
  reset(ctx: StateContext<ImportsDataTableStateModel>) {
    ctx.patchState({
      pagination: defaultPagination,
      columnsGroups: defaultColumnsV2,
      filtersGroups: defaultFiltersV2,
      sortBy: defaultSort,
      search: [],
      filterOpened: false,
      loading: true,
      errorLines: null,
      page: { totalElements: 0 },
    });
  }

  @Action(ResetPaginationAndLoadData, { cancelUncompleted: true })
  resetPaginationAndLoadData(
    ctx: StateContext<ImportsDataTableStateModel>
  ): Observable<any> {
    return ctx.dispatch([new ResetPagination(), new LoadImportDetails()]);
  }

  override addGroupParam(
    ctx: StateContext<ImportsDataTableStateModel>,
    groupKey: string,
    columnKey: string,
    param: DatatableParam
  ): void {
    ctx.setState(
      patch<ImportsDataTableStateModel>({
        filtersGroups: patch({
          [groupKey]: patch({
            columns: patch({
              [columnKey]: patch({
                params: patch<DatatableParam>({
                  ...param,
                  operator:
                    param.operator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .operator,
                  subOperator:
                    param.subOperator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .subOperator,
                }),
              }),
            }),
          }),
        }),
      })
    );
  }

  sort(ctx: StateContext<ImportsDataTableStateModel>, sort: Sorted): void {
    ctx.patchState({
      sortBy: {
        [sort.key]: {
          field: sort.key,
          order: sort.order,
        },
      },
    });

    ctx.dispatch(new LoadImportDetails());
  }

  private _removeAllFilters(ctx: StateContext<ImportsDataTableStateModel>) {
    ctx.patchState({
      filtersGroups: defaultFiltersV2,
      search: [],
      sortBy: defaultSort,
    });
  }

  private _fetchImporDetails(
    ctx: StateContext<ImportsDataTableStateModel>
  ): Observable<ImportDetails> {
    const state = ctx.getState();

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const importUuid = this.store.selectSnapshot(
      ImportsStateQueries.currentImport
    ).uuid;

    const requestInfo = this._buildFetchImportErrorsRequestInfo(ctx.getState());

    return this.importsService
      .fetchErrorLines(webshopUuid, importUuid, state.entityName, requestInfo)
      .pipe(
        catchError(e => {
          ctx.patchState({
            loading: false,
          });
          ctx.dispatch(new LoadFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _saveTableColumnsPersistance(
    ctx: StateContext<ImportsDataTableStateModel>,
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveColumnPersistanceRequestInfo(columnsGroups);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveColumnPersistanceRequestInfo(
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): AccountSettingsUpdate {
    const excludedColumns: string[] = this.buildExcludedColumns(columnsGroups);

    return {
      importDetailsTableExcludedColumns: excludedColumns,
      overrideImportDetailsTableExcludedColumns: true,
    };
  }

  private _saveTableSizePersistance(
    ctx: StateContext<ImportsDataTableStateModel>,
    newPageSize: number
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveTableSizePersistanceRequestInfo(newPageSize);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _updateAccountSettings(
    ctx: StateContext<ImportsDataTableStateModel>,
    requestInfo: AccountSettingsUpdate
  ): Observable<any> {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this.accountSettingsService
      .updateSettings(userUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _buildSaveTableSizePersistanceRequestInfo(
    newPageSize: number
  ): AccountSettingsUpdate {
    return {
      importsTablePageSize: newPageSize,
    };
  }

  private _buildFetchImportErrorsRequestInfo(
    state: ImportsDataTableStateModel
  ): ServiceRequestInfoV3 {
    const filtersWithoutGroups: Filter[] = Object.values(state.filtersGroups)
      .map(group => Object.values(group.columns))
      .flat();

    const requestInfo: ServiceRequestInfoV3 = {
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(state.sortBy),
        page: {
          from: state.pagination.page,
          size: state.pagination.size,
        },
      },
    };

    if (state.search !== null && !!state.search?.length) {
      requestInfo.queryData.query = this.buildQuery(state.search);
    }

    return requestInfo;
  }
}
