import { DateTime } from 'luxon';
import { DataAdapter } from 'src/app/shared/adapter/adapter';
import {
  ImportStates,
  Imports,
  OperationLabels,
  SourceLabels,
} from 'src/app/shared/models/tableImports/imports.model';
import { Import, Imports as ImportsV1 } from '../model/imports.model';
import {
  DynamicTags,
  TagVariantColor,
} from 'src/app/shared/components/design-system/tags/model/tag.model';

import { STRINGS } from 'src/app/features/imports/model/imports.strings';

export class ImportsAdapter implements DataAdapter<ImportsV1, Imports> {
  transform(data: ImportsV1): Imports {
    const imports = data.imports.map(tableImport => {
      return {
        uuid: tableImport.uuid,
        fileName: tableImport.fileName,
        createdAt: this.convertDate(tableImport.createdAt),
        updatedAt: this.convertDate(tableImport.updatedAt),
        state: tableImport.state,
        webshopUuid: tableImport.webshopUuid,
        accountEmail: tableImport.accountEmail,
        entityName: SourceLabels[tableImport.entity.name],
        entity: tableImport.entity,
        operation: OperationLabels[tableImport.operation],
        totalRecords: tableImport.totalRecords,
        extras: {
          tags: this.buildTags(tableImport),
        },
      };
    });

    return {
      data: imports,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }

  /**
   * Converts a string with a value in second to an actual date
   * @param date
   * @returns date
   */
  convertDate(date: string): string {
    const dateFormat = 'yyyy-MM-dd';

    return DateTime.fromSeconds(Number(date)).toFormat(dateFormat);
  }

  /**
   *
   * @param base build tags for the status column
   * @returns a tag
   */
  buildTags(base: Import): DynamicTags {
    const extras: DynamicTags = {
      iconTags: [],
      textTags: [],
      iconTextTags: [],
    };

    if (base.state.toString() === ImportStates.COMPLETED) {
      extras.textTags.push({
        text: STRINGS.metadata.status.completed,
        color: TagVariantColor.GREEN,
        variant: 'subtle',
        rounded: true,
      });
    } else if (base.state.toString() === ImportStates.COMPLETED_WITH_ERRORS) {
      extras.textTags.push({
        text: STRINGS.metadata.status.completedErrors,
        color: TagVariantColor.ORANGE,
        variant: 'subtle',
        rounded: true,
      });
    } else if (
      base.state.toString() === ImportStates.INVALID_OPERATION_TYPE ||
      base.state.toString() === ImportStates.FAILED_HEADERS ||
      base.state.toString() === ImportStates.FAILED_WITH_VALIDATION_ERRORS
    ) {
      extras.textTags.push({
        text: STRINGS.metadata.status.invalid,
        color: TagVariantColor.RED,
        variant: 'subtle',
        rounded: true,
      });
    } else if (base.state.toString() === ImportStates.FAILED_UNEXPECTEDLY) {
      extras.textTags.push({
        text: STRINGS.metadata.status.failed,
        color: TagVariantColor.RED,
        variant: 'subtle',
        rounded: true,
      });
    }

    return extras;
  }

  /**
   * Converts a string to sentence case
   * @param type
   * @returns sentence case
   */
  private toSentenceCase(type: string): string {
    if (type) {
      return (
        type.charAt(0).toUpperCase() +
        type.substring(1).toLowerCase().replace('_', ' ')
      );
    }
  }
}
