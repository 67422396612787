import { Selector } from '@ngxs/store';
import { ImportsState, ImportsStateModel } from './imports.state';
import { STRINGS } from '../../../model/imports.strings';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import {
  DatatableColumnV2,
  DatatableColumnV2ColumnGroup,
  DatatableColumnV2Group,
  DatatableColumnV2GroupList,
  DatatableColumnV2Groups,
  buildDisplayedColumns,
  buildSelectableColumnsList,
  fixTableColumnsOrder,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { Import } from 'src/app/shared/models/tableImports/imports.model';
import { WebshopState } from 'src/app/core/states/webshop.state';
import {
  ColumnFilter,
  ColumnFilterGroup,
  Filter,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import { COLUMNS_ORDER } from '../model/data-table.model';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';

export class ImportsStateQueries {
  @Selector([ImportsState])
  static loading(state: ImportsStateModel): boolean {
    return state.loading;
  }

  @Selector([ImportsState])
  static imports(state: ImportsStateModel): Import[] {
    return state.imports;
  }

  @Selector([ImportsState])
  static currentImport(state: ImportsStateModel): Import {
    return state.currentImport;
  }

  @Selector([ImportsState])
  static currentImportName(state: ImportsStateModel): string {
    return state.currentImport?.fileName || NOT_AVAILABLE_VALUE;
  }

  @Selector([ImportsState])
  static totalElements(state: ImportsStateModel): number {
    return state.page.totalElements;
  }

  @Selector([ImportsState])
  static featuredColumns(
    state: ImportsStateModel
  ): DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[] {
    return Object.values(state.columnsGroups).map(
      (group: DatatableColumnV2Group<DatatableColumnV2>) => {
        return {
          ...group,
          columns: Object.values(group.columns),
        };
      }
    );
  }

  @Selector([ImportsStateQueries.featuredColumns])
  static selectableColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> {
    const selectableColumns: DatatableColumnV2Groups<DatatableColumnV2ColumnGroup> =
      {};

    const selectableColumnsList = buildSelectableColumnsList(columns);

    selectableColumnsList.forEach(group => {
      selectableColumns[group.groupKey] = {
        groupKey: group.groupKey,
        groupName: group.groupName,
        columns: group.columns,
      };
    });

    return selectableColumns;
  }

  @Selector([ImportsStateQueries.featuredColumns])
  static displayedColumns(
    columns: DatatableColumnV2GroupList<DatatableColumnV2ColumnGroup>[]
  ): string[] {
    return buildDisplayedColumns(columns).sort((a, b) =>
      fixTableColumnsOrder(a, b, COLUMNS_ORDER)
    );
  }

  @Selector([ImportsStateQueries.displayedColumns])
  static lastDisplayedColumn(displayedColumns: string[]) {
    return (
      displayedColumns.filter(
        (column: string) => column !== STRINGS.columns.imports.actions.key
      ).length === 1
    );
  }

  @Selector([ImportsState])
  static page(state: ImportsStateModel): Pageable {
    return state.page;
  }

  @Selector([ImportsState])
  static pageSizeOptions(state: ImportsStateModel): number[] {
    return state.pagination.pageSizeOptions;
  }

  @Selector([ImportsState])
  static currentPage(state: ImportsStateModel): number {
    return state.pagination.page;
  }

  @Selector([ImportsState])
  static pageSize(state: ImportsStateModel): number {
    return state.pagination.size;
  }

  @Selector([ImportsState, WebshopState.webshopCurrencySymbol])
  static filters(
    state: ImportsStateModel,
    currencySymbol: string
  ): ColumnFilterGroup[] {
    return Object.entries(state.filtersGroups).map(([groupKey, group]) => {
      return {
        groupKey,
        groupName: group.groupName,
        filters: Object.entries(group.columns)
          .map(([key, filter]) => {
            if (STRINGS.columns.imports[filter.key]?.currency) {
              return {
                columnKey: key,
                ...filter,
                unit: currencySymbol,
              };
            }

            return {
              columnKey: key,
              ...filter,
            };
          })
          .filter((filter: Filter) => !filter.disabled),
      };
    });
  }

  @Selector([ImportsState, WebshopState.webshopCurrencySymbol])
  static activeFilters(
    state: ImportsStateModel,
    currencySymbol: string
  ): ColumnFilter[] {
    const filters = Object.values(state.filtersGroups).map(
      group => group.columns
    );

    return filters
      .map(filter => {
        return (
          Object.entries(filter)
            .map(([columnKey, filter]) => {
              if (STRINGS.columns.imports[filter.key]?.currency) {
                return {
                  columnKey,
                  ...filter,
                  unit: currencySymbol,
                };
              }

              return {
                columnKey,
                ...filter,
              };
            })
            .filter((filter: Filter) => filter.params.value) || []
        );
      })
      .flat();
  }

  @Selector([ImportsState])
  static search(state: ImportsStateModel): string[] {
    return state.search || [];
  }

  @Selector([ImportsState])
  static filterOpened(state: ImportsStateModel): boolean {
    return state.filterOpened;
  }
}
