import { DateTime } from 'luxon';
import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { Import } from 'src/app/shared/models/tableImports/imports.model';
import { Entity, ImportResponse } from '../model/imports.model';
import {
  DynamicTags,
  TagVariantColor,
} from 'src/app/shared/components/design-system/tags/model/tag.model';

import { STRINGS } from 'src/app/features/imports/model/imports.strings';

export class ImportAdapter implements DataAdapter<ImportResponse, Import> {
  transform(data: ImportResponse): Import {
    return {
      uuid: data.import.uuid,
      fileName: data.import.fileName,
      createdAt: this.convertDate(data.import.createdAt),
      updatedAt: this.convertDate(data.import.updatedAt),
      state: data.import.state,
      webshopUuid: data.import.webshopUuid,
      accountEmail: data.import.accountEmail,
      entityName: this.buildEntityName(data.import.entity),
      entity: data.import.entity,
      operation: data.import.operation,
      totalRecords: data.import.totalRecords,
      extras: {
        tags: this.buildTags(data),
      },
    };
  }

  buildEntityName(entity: Entity): string {
    entity.name = this.toSentenceCase(entity.name);

    return entity.name;
  }
  /**
   * Converts a string with a value in second to an actual date
   * @param date
   * @returns date
   */
  convertDate(date: string): string {
    const dateFormat = 'yyyy-MM-dd';

    return DateTime.fromSeconds(Number(date)).toFormat(dateFormat);
  }

  /**
   *
   * @param base build tags for the status column
   * @returns a tag
   */
  buildTags(base: ImportResponse): DynamicTags {
    const extras: DynamicTags = {
      iconTags: [],
      textTags: [],
      iconTextTags: [],
    };

    if (base.import.state.toString() === 'COMPLETED') {
      extras.textTags.push({
        text: STRINGS.metadata.status.completed,
        color: TagVariantColor.GREEN,
        variant: 'subtle',
        rounded: true,
      });
    } else if (base.import.state.toString() === 'COMPLETED_WITH_ERRORS') {
      extras.textTags.push({
        text: STRINGS.metadata.status.completedErrors,
        color: TagVariantColor.ORANGE,
        variant: 'subtle',
        rounded: true,
      });
    } else if (
      base.import.state.toString() === 'INVALID_OPERATION_TYPE' ||
      base.import.state.toString() === 'FAILED_HEADERS' ||
      base.import.state.toString() === 'FAILED_WITH_VALIDATION_ERRORS'
    ) {
      extras.textTags.push({
        text: STRINGS.metadata.status.invalid,
        color: TagVariantColor.RED,
        variant: 'subtle',
        rounded: true,
      });
    } else if (base.import.state.toString() === 'FAILED_UNEXPECTEDLY') {
      extras.textTags.push({
        text: STRINGS.metadata.status.failed,
        color: TagVariantColor.RED,
        variant: 'subtle',
        rounded: true,
      });
    }

    return extras;
  }

  /**
   * Converts a string to sentence case
   * @param type
   * @returns sentence case
   */
  private toSentenceCase(type: string): string {
    if (type) {
      return (
        type.charAt(0).toUpperCase() +
        type.substring(1).toLowerCase().replace('_', ' ')
      );
    }
  }
}
