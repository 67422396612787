import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { STRINGS } from './model/imports.strings';
import { Store } from '@ngxs/store';
import { WebshopState } from 'src/app/core/states/webshop.state';
import {
  Subject,
  distinctUntilKeyChanged,
  first,
  skip,
  switchMap,
  takeUntil,
} from 'rxjs';
import {
  InitializeImportsState,
  RemoveAllFilters,
} from './components/imports-data-table/actions/imports.actions';

@Component({
  selector: 'app-imports',
  templateUrl: './imports.page.html',
  styleUrls: ['./imports.page.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportsPage implements OnInit, OnDestroy {
  readonly METADATA_STRINGS = STRINGS.metadata;

  private _destroy$: Subject<void> = new Subject<void>();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(WebshopState.selected)
      .pipe(
        first(),
        switchMap(() => this.store.dispatch(new InitializeImportsState())),
        takeUntil(this._destroy$)
      )
      .subscribe();

    this.store
      .select(WebshopState.selected)
      .pipe(
        skip(1),
        distinctUntilKeyChanged('uuid'),
        switchMap(() => this.store.dispatch(new RemoveAllFilters())),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
