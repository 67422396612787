<app-datatable-container
  [loading]="loading()"
  [page]="currentPage()"
  [totalElements]="totalElements()"
  [pageSizeOptions]="pageSizeOptions()"
  [pageSize]="pageSize()"
  (paginationChanged)="onPageChange($event)">
  <table
    cdk-table
    [dataSource]="datasource()"
    appFilterableV2
    hideHideColumn
    (sorted)="onSort($event)"
    (hidden)="onColumnHidden($event)"
    (filtered)="onFiltered($event)">
    <!-- IDENTIFIER -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.IDENTIFIER.key">
      <th
        data-intercom-target="Import details table Identifier"
        aria-label="Import details table Identifier"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.IDENTIFIER.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.IDENTIFIER.name }}
      </th>
      <td
        cdk-cell
        *cdkCellDef="let element"
        class="column-strong restricted-column">
        <ng-container *ngIf="element.identifier !== null; else notAvailable">
          {{ element.identifier }}
        </ng-container>
      </td>
    </ng-container>

    <!-- ERRORS -->
    <ng-container [cdkColumnDef]="TABLE_COLUMNS.ERRORS.key">
      <th
        data-intercom-target="Import details table Errors"
        aria-label="Import details table Errors"
        cdk-header-cell
        *cdkHeaderCellDef
        [appFilterColumnHeaderV2]="
          TABLE_COLUMNS.ERRORS.key | filterColumn: RELATED_STATE | async
        ">
        {{ TABLE_COLUMNS.ERRORS.name }}
      </th>
      <td cdk-cell *cdkCellDef="let element" class="restricted-column">
        <ng-container *ngIf="element.errors; else notAvailable">
          <span *ngFor="let error of element.errors" class="import-error-label"
            >{{ error | sentenceCase }};</span
          >
        </ng-container>
      </td>
    </ng-container>

    <tr
      cdk-header-row
      *cdkHeaderRowDef="displayedColumns()"
      class="datatable-headers"></tr>
    <tr
      cdk-row
      *cdkRowDef="let row; columns: displayedColumns()"
      class="datatable-rows"></tr>
  </table>
</app-datatable-container>

<ng-template #notAvailable>
  <span class="gray">{{ NOT_AVAILABLE }}</span>
</ng-template>
