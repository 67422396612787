import {
  Action,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import {
  columnsGroupsMap,
  defaultColumnsV2,
  defaultFiltersV2,
  defaultPagination,
  defaultSort,
  filtersGroupsMap,
} from '../model/data-table.model';
import { Injectable } from '@angular/core';
import {
  AddFilterParam,
  AddSearchParam,
  ColumnsSelected,
  HideColumn,
  InitializeImportsState,
  LoadImport,
  LoadImports,
  Paginate,
  ReloadDatatable,
  RemoveAllFilters,
  RemoveSearchParam,
  ResetImports,
  ResetPagination,
  ResetPaginationAndLoadData,
  Sort,
  ToggleFilter,
  UploadData,
  UploadSupplierData,
} from '../actions/imports.actions';
import { Observable, catchError, mergeMap, of, tap } from 'rxjs';
import { LoadFailed, SaveFailed } from 'src/app/core/actions/app.action';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import {
  DatatableState,
  DatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/state/data-table.state';
import {
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableParam,
  defaultDatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  Sorted,
  SortedOrder,
} from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import {
  Import,
  Imports,
} from 'src/app/shared/models/tableImports/imports.model';
import { ImportsService } from 'src/app/core/api/imports/imports.service';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { RetryImport } from '../../import-details/components/import-details-data-table/actions/import-details-data-table.actions';
import { WebshopSelected } from 'src/app/core/actions/webshop.action';
import { AccountSettingsUpdate } from 'src/app/shared/models/account/account-settings.model';
import { AccountState } from 'src/app/core/states/account.state';
import { AccountSettingsService } from 'src/app/core/api/account/v2/account-settings.service';
import { FilterTypesOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';
import { LoadAccountSettings } from 'src/app/core/actions/settings.action';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { ResetImportData } from 'src/app/shared/components/imports-dialog/actions/imports-dialog.action';
import { ResetImportSuppliersData } from 'src/app/shared/components/import-suppliers-dialog/components/import-suppliers-update-dialog/actions/import-suppliers-dialog.action';
import { Filter } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';

export interface ImportsStateModel extends DatatableStateModel {
  imports: Import[];
  page: Pageable;
  loading: boolean;
  currentImport: Import;
}

@State<ImportsStateModel>({
  name: 'ImportsState',
  defaults: {
    ...defaultDatatableStateModel,
    pagination: defaultPagination,
    columnsGroups: defaultColumnsV2,
    filtersGroups: defaultFiltersV2,
    sortBy: defaultSort,
    search: null,
    filterOpened: false,
    imports: [],
    currentImport: null,
    page: { totalElements: 0 },
    loading: false,
  },
})
@Injectable()
export class ImportsState extends DatatableState {
  static filterDataByColumnKey(columnKey: string) {
    return createSelector([ImportsState], (state: ImportsStateModel) => {
      const groupKey = filtersGroupsMap.get(columnKey);

      if (!groupKey) {
        throw new Error(`Column group ${groupKey} does not exist`);
      }

      if (state.filtersGroups[groupKey].columns[columnKey] === undefined) {
        throw new Error(`Column ${columnKey} does not exist`);
      }

      const sortBy = new Map(Object.entries(state.sortBy));

      return {
        columnKey,
        filter: state.filtersGroups[groupKey].columns[columnKey],
        filtered:
          !!state.filtersGroups[groupKey].columns[columnKey].params.value,
        sorted: sortBy.has(
          state.filtersGroups[groupKey].columns[columnKey]?.key
        )
          ? (sortBy.get(state.filtersGroups[groupKey].columns[columnKey].key)
              .order as SortedOrder)
          : null,
      };
    });
  }

  constructor(
    private store: Store,
    private importsService: ImportsService,
    private accountSettingsService: AccountSettingsService
  ) {
    super();
  }

  @Action(InitializeImportsState, { cancelUncompleted: true })
  initialize(ctx: StateContext<ImportsStateModel>) {
    const tableSettings = this.store.selectSnapshot(
      AccountSettingsState.importsTableSettings
    );

    const columns = this.initializeColumnsFromPersistence(
      ctx.getState().columnsGroups,
      tableSettings.excludedColumns
    );

    ctx.setState(
      patch<ImportsStateModel>({
        columnsGroups: columns,
        pagination: patch<DatatablePagination>({
          size: tableSettings.pageSize,
        }),
      })
    );

    return ctx.dispatch(new LoadImports());
  }

  @Action(LoadImports, { cancelUncompleted: true })
  loadImports(ctx: StateContext<ImportsStateModel>) {
    ctx.patchState({
      loading: true,
    });

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo = this._buildFetchImportsRequestInfo(ctx.getState());

    return this._fetchImports(ctx, webshopUuid, requestInfo).pipe(
      tap((imports: Imports) => {
        ctx.patchState({
          loading: false,
          imports: imports.data,
          page: { totalElements: imports.metadata.page.totalElements },
        });
      })
    );
  }

  @Action(ReloadDatatable)
  reload(ctx: StateContext<ImportsStateModel>) {
    return ctx.dispatch(new LoadImports());
  }

  @Action(ReloadDatatable)
  handleRefresh(ctx: StateContext<ImportsStateModel>) {
    return this.disableRefresh(ctx);
  }

  @Action(LoadImport, { cancelUncompleted: true })
  loadImport(ctx: StateContext<ImportsStateModel>, payload: LoadImport) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this._fetchImport(ctx, webshopUuid, payload.importUUID).pipe(
      tap((importData: Import) => {
        ctx.patchState({
          currentImport: {
            uuid: importData.uuid,
            fileName: importData.fileName,
            createdAt: importData.createdAt,
            updatedAt: importData.updatedAt,
            state: importData.state,
            webshopUuid: importData.webshopUuid,
            accountEmail: importData.accountEmail,
            entity: importData.entity,
            entityName: importData.entityName,
            operation: importData.operation,
            totalRecords: importData.totalRecords,
            extras: importData.extras,
          },
        });
      })
    );
  }

  @Action(Paginate, { cancelUncompleted: true })
  paginate(ctx: StateContext<ImportsStateModel>, payload: Paginate) {
    const state = ctx.getState();

    const didPageSizeChange =
      ctx.getState().pagination.size !== payload.pagination.pageSize;

    if (didPageSizeChange) {
      return this._saveTableSizePersistance(
        ctx,
        payload.pagination.pageSize
      ).pipe(
        tap(() => {
          ctx.patchState({
            pagination: {
              ...state.pagination,
              page: payload.pagination.pageIndex,
              size: payload.pagination.pageSize,
            },
          });
        }),
        mergeMap(() => ctx.dispatch(new LoadImports()))
      );
    }

    ctx.patchState({
      pagination: {
        ...state.pagination,
        page: payload.pagination.pageIndex,
        size: payload.pagination.pageSize,
      },
    });

    return ctx.dispatch(new LoadImports());
  }

  @Action(AddFilterParam, { cancelUncompleted: true })
  addFilterParam(
    ctx: StateContext<ImportsStateModel>,
    payload: AddFilterParam
  ) {
    const groupKey = filtersGroupsMap.get(payload.param.columnKey);

    if (!groupKey) return;

    this.addGroupParam(ctx, groupKey, payload.param.columnKey, {
      operator: payload.param.optionSelected as FilterTypesOptionsV2,
      subOperator: payload.param.subOperator,
      value: payload.param.values,
    });

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(AddSearchParam, { cancelUncompleted: true })
  addSearchParam(
    ctx: StateContext<ImportsStateModel>,
    payload: AddSearchParam
  ) {
    ctx.setState(
      patch<ImportsStateModel>({ search: append<string>([payload.param]) })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(RemoveSearchParam, { cancelUncompleted: true })
  removeSearchParam(
    ctx: StateContext<ImportsStateModel>,
    payload: RemoveSearchParam
  ) {
    ctx.setState(
      patch<ImportsStateModel>({
        search: removeItem<string>(
          searchParam => searchParam === payload.param
        ),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(Sort, { cancelUncompleted: true })
  sortTable(ctx: StateContext<ImportsStateModel>, payload: Sort) {
    return this.sort(ctx, payload.sort);
  }

  @Action(ColumnsSelected, { cancelUncompleted: true })
  columnsSelected(
    ctx: StateContext<ImportsStateModel>,
    payload: ColumnsSelected
  ) {
    return this._saveTableColumnsPersistance(
      ctx,
      payload.selection.columnsGroups
    ).pipe(
      tap(() => {
        ctx.setState(
          patch({
            columnsGroups: {
              ...ctx.getState().columnsGroups,
              ...payload.selection.columnsGroups,
            },
          })
        );
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(HideColumn, { cancelUncompleted: true })
  hideColumn(ctx: StateContext<ImportsStateModel>, payload: HideColumn) {
    const groupKey = columnsGroupsMap.get(payload.columnKey);

    if (!groupKey) return;

    const newColumnSelection = this.hideGroupColumnFromColumnKey(
      ctx.getState().columnsGroups,
      groupKey,
      payload.columnKey
    );

    return this._saveTableColumnsPersistance(ctx, newColumnSelection).pipe(
      tap(() => {
        ctx.patchState({
          columnsGroups: newColumnSelection,
        });
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(RemoveAllFilters, { cancelUncompleted: true })
  removeAllFilters(ctx: StateContext<ImportsStateModel>) {
    this._removeAllFilters(ctx);

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(WebshopSelected)
  removeAllFiltersOnWebshopChange(ctx: StateContext<ImportsStateModel>) {
    this._removeAllFilters(ctx);
  }

  @Action(ToggleFilter, { cancelUncompleted: true })
  toggleFilter(ctx: StateContext<ImportsStateModel>) {
    ctx.patchState({
      filterOpened: !ctx.getState().filterOpened,
    });
  }

  @Action(ResetPagination)
  resetPagination(
    ctx: StateContext<ImportsStateModel>,
    payload: ResetPagination
  ) {
    ctx.patchState({
      pagination: {
        ...ctx.getState().pagination,
        page: payload.page,
      },
    });
  }

  @Action(UploadData, { cancelUncompleted: true })
  uploadData(ctx: StateContext<ImportsStateModel>, payload: UploadData) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected)?.uuid;

    return this.importsService
      .uploadData(webshopUUID, payload.identifier, payload.data)
      .pipe(
        tap(() => {
          ctx.dispatch(new ResetImportData());
        }),
        catchError(() => {
          ctx.dispatch(new ResetImportData());

          return of(null);
        })
      );
  }

  @Action(UploadSupplierData, { cancelUncompleted: true })
  uploadSupplierData(
    ctx: StateContext<ImportsStateModel>,
    payload: UploadSupplierData
  ) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected)?.uuid;

    return this.importsService
      .uploadSupplierData(
        webshopUUID,
        payload.operation,
        payload.identifier,
        payload.data
      )
      .pipe(
        tap(() => {
          ctx.dispatch(new ResetImportSuppliersData());
        }),
        catchError(() => {
          ctx.dispatch(new ResetImportSuppliersData());

          return of(null);
        })
      );
  }

  @Action(RetryImport, { cancelUncompleted: true })
  retryImport(_ctx: StateContext<ImportsStateModel>, payload: RetryImport) {
    const webshopUUID = this.store.selectSnapshot(WebshopState.selected)?.uuid;

    return this.importsService
      .retryImport(webshopUUID, payload.importUuid)
      .pipe(
        catchError(e => {
          throw new Error(e.message || e);
        })
      );
  }

  @Action(ResetImports, { cancelUncompleted: true })
  reset(ctx: StateContext<ImportsStateModel>) {
    ctx.patchState({
      pagination: defaultPagination,
      columnsGroups: defaultColumnsV2,
      filtersGroups: defaultFiltersV2,
      sortBy: defaultSort,
      search: null,
      filterOpened: false,
      imports: [],
      currentImport: null,
      page: { totalElements: 0 },
      loading: false,
    });
  }

  @Action(ResetPaginationAndLoadData, { cancelUncompleted: true })
  resetPaginationAndLoadData(ctx: StateContext<ImportsStateModel>) {
    return ctx.dispatch([new ResetPagination(), new LoadImports()]);
  }

  override addGroupParam(
    ctx: StateContext<ImportsStateModel>,
    groupKey: string,
    columnKey: string,
    param: DatatableParam
  ): void {
    ctx.setState(
      patch<ImportsStateModel>({
        filtersGroups: patch({
          [groupKey]: patch({
            columns: patch({
              [columnKey]: patch({
                params: patch<DatatableParam>({
                  ...param,
                  operator:
                    param.operator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .operator,
                  subOperator:
                    param.subOperator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .subOperator,
                }),
              }),
            }),
          }),
        }),
      })
    );
  }

  sort(ctx: StateContext<ImportsStateModel>, sort: Sorted): void {
    ctx.patchState({
      sortBy: {
        [sort.key]: {
          field: sort.key,
          order: sort.order,
        },
      },
    });

    ctx.dispatch(new LoadImports());
  }

  private _saveTableColumnsPersistance(
    ctx: StateContext<ImportsStateModel>,
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveColumnPersistanceRequestInfo(columnsGroups);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveColumnPersistanceRequestInfo(
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): AccountSettingsUpdate {
    const excludedColumns: string[] = this.buildExcludedColumns(columnsGroups);

    return {
      importsTableExcludedColumns: excludedColumns,
      overrideImportsTableExcludedColumns: true,
    };
  }

  private _saveTableSizePersistance(
    ctx: StateContext<ImportsStateModel>,
    newPageSize: number
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveTableSizePersistanceRequestInfo(newPageSize);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _updateAccountSettings(
    ctx: StateContext<ImportsStateModel>,
    requestInfo: AccountSettingsUpdate
  ): Observable<any> {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this.accountSettingsService
      .updateSettings(userUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _buildSaveTableSizePersistanceRequestInfo(
    newPageSize: number
  ): AccountSettingsUpdate {
    return {
      importsTablePageSize: newPageSize,
    };
  }

  private _removeAllFilters(ctx: StateContext<ImportsStateModel>) {
    ctx.patchState({
      filtersGroups: defaultFiltersV2,
      search: [],
      sortBy: defaultSort,
    });
  }

  private _fetchImports(
    ctx: StateContext<ImportsStateModel>,
    webshopUuid: string,
    requestInfo: ServiceRequestInfoV3
  ) {
    return this.importsService.findAll(webshopUuid, requestInfo).pipe(
      catchError(e => {
        ctx.dispatch(new LoadFailed());

        throw new Error(e.message || e);
      })
    );
  }

  private _fetchImport(
    ctx: StateContext<ImportsStateModel>,
    webshopUuid: string,
    importUuid: string
  ) {
    return this.importsService.findOne(webshopUuid, importUuid).pipe(
      catchError(e => {
        ctx.dispatch(new LoadFailed());

        throw new Error(e.message || e);
      })
    );
  }

  private _buildFetchImportsRequestInfo(
    state: ImportsStateModel
  ): ServiceRequestInfoV3 {
    const filtersWithoutGroups: Filter[] = Object.values(state.filtersGroups)
      .map(group => Object.values(group.columns))
      .flat();

    const requestInfo: ServiceRequestInfoV3 = {
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(state.sortBy),
        page: {
          from: state.pagination.page,
          size: state.pagination.size,
        },
      },
    };

    if (state.search !== null && !!state.search?.length) {
      requestInfo.queryData.query = this.buildQuery(state.search);
    }

    return requestInfo;
  }
}
