import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { ImportDetails as ImportDetailsV1 } from '../model/imports.model';
import { ImportDetails } from 'src/app/features/imports/components/import-details/model/import-details.model';

export class ImportDetailsAdapter
  implements DataAdapter<ImportDetailsV1, ImportDetails>
{
  transform(data: ImportDetailsV1): ImportDetails {
    const errors = data.lines.map(line => {
      return {
        identifier: line.identifier,
        errors: line.errors,
      };
    });

    return {
      data: errors,
      metadata: { page: { totalElements: data.meta.page.totalElements ?? 0 } },
    };
  }
}
