import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { ImportDetails } from '../../../model/import-details.model';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';

export class InitializeImportDetails {
  static readonly type = '[Import Details] Initialize';

  constructor(
    public importUuid: string,
    public entityName: string
  ) {}
}

export class LoadImportDetails {
  static readonly type = '[Import Details] Load Import Details';
}

export class ResetImportDetails {
  static readonly type = '[Import Details] Reset Import Details';
}

export class AddSearchParam {
  static readonly type = '[Import Details] Add Search Param';

  constructor(public param: string[] | null) {}
}

export class AddFilterParam {
  static readonly type = '[Import Details] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Import Details] Sort';

  constructor(public sort: Sorted) {}
}

export class RemoveFilterParam {
  static readonly type = '[Import Details] Remove Filter Param';
}

export class RemoveAllFilters {
  static readonly type = '[Import Details] Remove All Filters';
}

export class Paginate {
  static readonly type = '[Import Details] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type = '[Import Details] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Import Details] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class HideColumn {
  static readonly type = '[Import Details] Hide Column';

  constructor(public columnKey: string) {}
}

export class ToggleFilter {
  static readonly type = '[Import Details] Toggle Filter';
}

export class RetryImport {
  static readonly type = '[Import Details] Retry Import';

  constructor(public importUuid: string) {}
}

export class ToggleRowSelection {
  static readonly type = '[Import Details] Toggle Row Selection';

  constructor(
    public rowKey: string,
    public isSelected: boolean,
    public errors: ImportDetails
  ) {}
}

export class ResetPaginationAndLoadData {
  static readonly type = '[Import Details] Reset Pagination And Load Data';
}
