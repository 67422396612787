import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';

import { ConfigStoreService } from '../../services/config-store.service';

import { ImportsAdapter } from './adapters/imports.adapter';
import {
  Import,
  ImportDetails as ImportDetailsV1,
  ImportResponse,
  Imports as ImportsV1,
} from './model/imports.model';
import { Imports } from 'src/app/shared/models/tableImports/imports.model';
import { ServiceRequestInfoV3 } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { ImportDetailsAdapter } from './adapters/import-details.adapter';
import { ImportDetails } from 'src/app/features/imports/components/import-details/model/import-details.model';
import { ImportAdapter } from './adapters/import.adapter';

export interface PageableData {
  page: number;
  size: number;
  filter: string;
  sortBy: string;
  order: string;
}

export interface ImportsPayload {
  webshopUUID: string;
}

export interface ImportsPaginatedPayload {
  page: number;
  size: number;
  filter: string;
  sortBy: string;
  order: string;
}

@Injectable({ providedIn: 'root' })
export class ImportsService {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _findAll(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ImportsV1> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'importsGRPC',
      'v1',
      'findAll',
      replacements
    );
    return this.http.post<ImportsV1>(endpoint, payload);
  }

  private _fetchErrorLines(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ImportDetailsV1> {
    let endpoint: string = this.configStore.getEndpointForResource(
      'productImport',
      'v1',
      'fetchErrorLines',
      replacements
    );

    return this.http.post<ImportDetailsV1>(endpoint, payload);
  }

  private _fetchSupplierErrorLines(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<ImportDetailsV1> {
    let endpoint: string = this.configStore.getEndpointForResource(
      'supplyImport',
      'v1',
      'fetchErrorLines',
      replacements
    );

    return this.http.post<ImportDetailsV1>(endpoint, payload);
  }

  private _findOne(replacements: UrlReplacement[]): Observable<ImportResponse> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'importsGRPC',
      'v1',
      'findOne',
      replacements
    );

    return this.http.get<ImportResponse>(endpoint);
  }

  private _uploadData(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'imports',
      'v1',
      'upload',
      replacements
    );

    return this.http.post<any>(endpoint, payload, {
      headers: { enctype: 'multipart/form-data' },
    });
  }

  private _uploadSupplierData(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'imports',
      'v1',
      'uploadSuppliers',
      replacements
    );

    return this.http.post<any>(endpoint, payload, {
      headers: { enctype: 'multipart/form-data' },
    });
  }

  private _retryImport(replacements: UrlReplacement[]): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'importsGRPC',
      'v1',
      'retry',
      replacements
    );

    return this.http.post<any>(endpoint, {});
  }

  findAll(
    webshopUUID: string,
    payload: ServiceRequestInfoV3
  ): Observable<Imports> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUUID },
    ];

    return this._findAll(payload, replacements).pipe(
      switchMap(data => of(new ImportsAdapter().transform(data)))
    );
  }

  fetchErrorLines(
    webshopUUID: string,
    importUUID: string,
    entityName: string,
    payload: ServiceRequestInfoV3
  ): Observable<ImportDetails> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUUID },
      { name: 'uuid', value: importUUID },
    ];

    if (entityName === 'Supplier') {
      return this._fetchSupplierErrorLines(payload, replacements).pipe(
        switchMap(data => of(new ImportDetailsAdapter().transform(data)))
      );
    }

    return this._fetchErrorLines(payload, replacements).pipe(
      switchMap(data => of(new ImportDetailsAdapter().transform(data)))
    );
  }

  findOne(webshopUUID: string, importUUID: string): Observable<Import> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUUID },
      { name: 'uuid', value: importUUID },
    ];

    return this._findOne(replacements).pipe(
      switchMap(data => of(new ImportAdapter().transform(data)))
    );
  }

  uploadData(
    webshopUUID: string,
    identifier: string,
    payload: any
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'identifier', value: identifier },
    ];

    const formData = new FormData();

    formData.append('data', JSON.stringify(payload));

    return this._uploadData(payload, replacements);
  }

  uploadSupplierData(
    webshopUUID: string,
    operation: string,
    identifier: string,
    payload: any
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'operation', value: operation },
      { name: 'identifier', value: identifier },
    ];

    const formData = new FormData();

    formData.append('data', JSON.stringify(payload));

    return this._uploadSupplierData(payload, replacements);
  }

  retryImport(webshopUUID: string, importUUID: string): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUuid', value: webshopUUID },
      { name: 'uuid', value: importUUID },
    ];

    return this._retryImport(replacements);
  }
}
