import {
  ColumnOrder,
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableSort,
  buildGroupsMap,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { STRINGS } from '../../../model/imports.strings';
import { SortOrder } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import {
  Filter,
  FilterTypes,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import {
  ServiceRequestInfoV3FilteringType,
  ServiceRequestInfoV3Operators,
} from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { SourceLabels } from 'src/app/shared/models/tableImports/imports.model';
import { FilterTypeOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';
import { COLUMNS_GROUPS } from 'src/app/shared/models/datatable/v2/columns-groups.model';

export const defaultSort: DatatableSort = {
  [STRINGS.columns.imports.createdAt.key]: {
    field: STRINGS.columns.imports.createdAt.filterKey,
    order: SortOrder.DESC,
  },
};

export const COLUMNS_ORDER: ColumnOrder = {
  [STRINGS.columns.imports.name.key]: 1,
  [STRINGS.columns.imports.source.key]: 2,
  [STRINGS.columns.imports.identifier.key]: 3,
  [STRINGS.columns.imports.operation.key]: 4,
  [STRINGS.columns.imports.createdAt.key]: 5,
  [STRINGS.columns.imports.records.key]: 6,
  [STRINGS.columns.imports.status.key]: 7,
  [STRINGS.columns.imports.actions.key]: 8,
};

export const defaultColumnsV2: DatatableColumnV2Groups<DatatableColumnV2> = {
  [COLUMNS_GROUPS.GENERAL.key]: {
    groupKey: COLUMNS_GROUPS.GENERAL.key,
    groupName: COLUMNS_GROUPS.GENERAL.name,
    columns: {
      [STRINGS.columns.imports.name.key]: {
        name: STRINGS.columns.imports.name.key,
        displayName: STRINGS.columns.imports.name.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.imports.source.key]: {
        name: STRINGS.columns.imports.source.key,
        displayName: STRINGS.columns.imports.source.name,
        checked: true,
        type: FilterTypes.FILTER_LIST,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.imports.identifier.key]: {
        name: STRINGS.columns.imports.identifier.key,
        displayName: STRINGS.columns.imports.identifier.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.imports.operation.key]: {
        name: STRINGS.columns.imports.operation.key,
        displayName: STRINGS.columns.imports.operation.name,
        checked: true,
        type: FilterTypes.FILTER_LIST,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.imports.createdAt.key]: {
        name: STRINGS.columns.imports.createdAt.key,
        displayName: STRINGS.columns.imports.createdAt.name,
        checked: true,
        type: FilterTypes.FILTER_DATE,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.imports.records.key]: {
        name: STRINGS.columns.imports.records.key,
        displayName: STRINGS.columns.imports.records.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.imports.status.key]: {
        name: STRINGS.columns.imports.status.key,
        displayName: STRINGS.columns.imports.status.name,
        checked: true,
        type: FilterTypes.FILTER_LIST,
        selectable: true,
        disabled: false,
      },
    },
  },
  [COLUMNS_GROUPS.FIXED_END.key]: {
    groupKey: COLUMNS_GROUPS.FIXED_END.key,
    groupName: COLUMNS_GROUPS.FIXED_END.name,
    columns: {
      [STRINGS.columns.imports.actions.key]: {
        name: STRINGS.columns.imports.actions.key,
        displayName: STRINGS.columns.imports.actions.name,
        checked: true,
        type: null,
        selectable: false,
        disabled: false,
      },
    },
  },
};

export const columnsGroupsMap: Map<string, string> =
  buildGroupsMap(defaultColumnsV2);

export const defaultFiltersV2: DatatableColumnV2Groups<Filter> = {
  [COLUMNS_GROUPS.GENERAL.key]: {
    groupKey: COLUMNS_GROUPS.GENERAL.key,
    groupName: COLUMNS_GROUPS.GENERAL.name,
    columns: {
      [STRINGS.columns.imports.name.key]: {
        name: STRINGS.columns.imports.name.name,
        key: STRINGS.columns.imports.name.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.imports.source.key]: {
        name: STRINGS.columns.imports.source.name,
        key: STRINGS.columns.imports.source.filterKey,
        uiModel: FilterTypes.FILTER_LIST,
        options: [
          {
            key: SourceLabels['WEBSHOP_PRODUCT'],
            value: 'WEBSHOP_PRODUCT',
          },
          {
            key: SourceLabels['SUPPLIER'],
            value: 'SUPPLIER',
          },
        ],
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.OR,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.imports.operation.key]: {
        name: STRINGS.columns.imports.operation.name,
        key: STRINGS.columns.imports.operation.filterKey,
        uiModel: FilterTypes.FILTER_LIST,
        options: [
          {
            key: 'Insert',
            value: 0,
          },
          {
            key: 'Update',
            value: 1,
          },
        ],
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.OR,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.imports.identifier.key]: {
        name: STRINGS.columns.imports.identifier.name,
        key: STRINGS.columns.imports.identifier.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.imports.createdAt.key]: {
        name: STRINGS.columns.imports.createdAt.name,
        key: STRINGS.columns.imports.createdAt.filterKey,
        uiModel: FilterTypes.FILTER_DATE,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.DATETIME,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.imports.records.key]: {
        name: STRINGS.columns.imports.records.name,
        key: STRINGS.columns.imports.records.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.imports.status.key]: {
        name: STRINGS.columns.imports.status.name,
        key: STRINGS.columns.imports.status.filterKey,
        uiModel: FilterTypes.FILTER_LIST,
        options: [
          {
            key: STRINGS.metadata.states.inQueue,
            value: '0',
          },
          {
            key: STRINGS.metadata.states.loading,
            value: '1',
          },
          {
            key: STRINGS.metadata.status.completed,
            value: '2',
          },
          {
            key: STRINGS.metadata.status.completedErrors,
            value: '3',
          },
          {
            key: STRINGS.metadata.status.invalid,
            value: ['4', '5', '6'],
          },
          {
            key: STRINGS.metadata.status.failed,
            value: '7',
          },
        ],
        validations: {
          min: null,
          max: null,
          step: null,
        },

        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: FilterTypeOptionsV2.EQUALS,
          subOperator: ServiceRequestInfoV3Operators.OR,
          value: null,
        },

        disabled: false,
      },
    },
  },
};

export const filtersGroupsMap: Map<string, string> =
  buildGroupsMap(defaultFiltersV2);

export const defaultPagination: DatatablePagination = {
  page: 0,
  size: 50,
  pageSizeOptions: [10, 15, 25, 50, 75, 100, 125, 150, 200],
};
