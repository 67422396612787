import { Pageable } from '../../components/data-table-v2/model/pageable.model';

export interface Import {
  uuid: string;
  fileName: string;
  createdAt: string;
  updatedAt: string;
  state: ImportState;
  webshopUuid: string;
  accountEmail: string;
  entity: Entity;
  entityName: string;
  operation: string;
  totalRecords: number;
  extras?: any;
}

export interface Imports {
  data: Import[];
  metadata: Metadata;
}

export interface Metadata {
  page: Pageable;
}

export interface Entity {
  name: string;
  identifier: string;
}

export enum ImportStates {
  IN_QUEUE = 'IN_QUEUE',
  IMPORTING = 'IMPORTING',
  COMPLETED = 'COMPLETED',
  COMPLETED_WITH_ERRORS = 'COMPLETED_WITH_ERRORS',
  INVALID_OPERATION_TYPE = 'INVALID_OPERATION_TYPE',
  FAILED_HEADERS = 'FAILED_HEADERS',
  FAILED_WITH_VALIDATION_ERRORS = 'FAILED_WITH_VALIDATION_ERRORS',
  FAILED_UNEXPECTEDLY = 'FAILED_UNEXPECTEDLY',
}

export type ImportState =
  | ImportStates.IN_QUEUE
  | ImportStates.IMPORTING
  | ImportStates.COMPLETED
  | ImportStates.COMPLETED_WITH_ERRORS
  | ImportStates.INVALID_OPERATION_TYPE
  | ImportStates.FAILED_HEADERS
  | ImportStates.FAILED_WITH_VALIDATION_ERRORS
  | ImportStates.FAILED_UNEXPECTEDLY;

export enum ImportSources {
  WEBSHOP_PRODUCT = 'WEBSHOP_PRODUCT',
}

export const SourceLabels = {
  WEBSHOP_PRODUCT: 'Product',
  SUPPLIER: 'Supplier',
};

export const OperationLabels = {
  0: 'Insert',
  1: 'Update',
};
